import React, { Component } from "react"
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl"
import {
  Container,
  Grid,
  Header as SUIHeader,
  Form,
  Button as SUIButton,
  Image
} from "semantic-ui-react"
import styled from "styled-components"
import QrCode from "../images/android-qr.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from "../components/icon"

const styles = {
  green: "#63962f",
}

const Header = styled(SUIHeader)`
  color: ${props => (props.secondary ? "black" : styles.green)};
  font-weight: bold;
  font-size: ${props => {
    if (props.as === "h1") {
      return "2.5em"
    } else if (props.as === "h2") {
      return "2.1em"
    }
  }};
  text-align: center;
  padding-bottom: 0.2em;
`
const Button = styled.a`
  background: #fc9e4e !important;
  color: white !important;
  font-size: 1.5em !important;
  padding: 1em 2em !important;
`
const Small = styled.small`
  display: block;
  font-size: ${props => (props.smaller ? "0.8em" : "1em")};
  font-styling: ${props => (props.smaller ? "italic" : "inherit")};
  padding-top: ${props => (props.padding ? "1em" : "0em")};
`

const Instruction = styled.p`
  font-size: 1.3em;

  @media only screen and (min-width: 1068px) {
    padding-left: 4em;
    text-align: left;
  }
  @media only screen and (max-width: 767px) {
    text-align: center;
    padding-bottom: 3em;
  }
`

const ButtonContainer = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    text-align: center !important;
  }
`

class Android extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Android APK"
          keywords={[`gatsby`, `application`, `react`]}
        />
        <Container>
          <Header as="h1">
            {this.props.intl.formatMessage({ id: "ad_title" })}
          </Header>
          <p>{this.props.intl.formatMessage({ id: "ad_paragraph_1" })}</p>
          <p>{this.props.intl.formatMessage({ id: "ad_paragraph_2" })}</p>
          <p>{this.props.intl.formatMessage({ id: "ad_paragraph_3" })}</p>
          <p>{this.props.intl.formatMessage({ id: "ad_paragraph_4" })}</p>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Button
                  href="https://iswp-assets.s3.ap-southeast-1.amazonaws.com/market/myiswp.prod.apk"
                  className={`ui button ${this.props.className}`}
                >
                  {this.props.intl.formatMessage({ id: "ad_button" })}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Small padding>
            {this.props.intl.formatMessage({ id: "ad_button_note" })}
          </Small>
          <Small smaller>
            {this.props.intl.formatMessage({ id: "ad_button_footnote" })}
          </Small>
          <Small smaller>
            {this.props.intl.formatMessage({ id: "ad_button_footnote_2" })}
          </Small>

          <Image style={{ marginTop: '20px' }} src={QrCode} size="small" centered/>

          <Header as="h2" secondary>
            {this.props.intl.formatMessage({ id: "ad_section_1" })}
          </Header>
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.intl.formatHTMLMessage({
                id: "ad_paragraph_5",
              }),
            }}
          />
          <Grid stackable>
            <Grid.Row columns="2" verticalAlign="middle" centered>
              <ButtonContainer textAlign="right" removePadding>
                <a href="https://play.google.com/store/apps/details?id=com.morneaushepell.enterprise.myiswp&hl=zh_CN">
                  <img
                    alt="Google Play Download"
                    src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,h_90/v1553041673/ISWP-CHINA/Android%20Page/en_badge_web_generic-1_2x.png"
                  />
                </a>
              </ButtonContainer>
              <ButtonContainer textAlign="left">
                <a href="https://itunes.apple.com/cn/app/my-iswp/id1388495381?mt=8">
                  <img
                    alt="Apple Download"
                    src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,h_60/v1553041672/ISWP-CHINA/Android%20Page/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917_2x.png"
                  />
                </a>
              </ButtonContainer>
            </Grid.Row>
          </Grid>
          <Header as="h2" secondary>
            {this.props.intl.formatMessage({ id: "ad_section_2" })}
          </Header>
          <hr />
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.intl.formatHTMLMessage({
                id: "ad_paragraph_6",
              }),
            }}
          />
          <Grid stackable>
            <Grid.Row columns="2" style={{ marginTop: "4em" }}>
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041566/ISWP-CHINA/Android%20Page/Rectangle_8.png"
                  alt="Screenshot 1"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_1" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041569/ISWP-CHINA/Android%20Page/Rectangle_6.png"
                  alt="Screenshot 2"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_2" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041569/ISWP-CHINA/Android%20Page/Rectangle_6.png"
                  alt="Screenshot 3"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_3" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041567/ISWP-CHINA/Android%20Page/Rectangle_4.png"
                  alt="Screenshot 4"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_4" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041566/ISWP-CHINA/Android%20Page/Rectangle_9.png"
                  alt="Screenshot 5"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_5" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041567/ISWP-CHINA/Android%20Page/Rectangle_10.png"
                  alt="Screenshot 6"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "ad_instruction_6",
                    }),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,w_187/v1553041742/ISWP-CHINA/Android%20Page/MyISWP_Android_Download_Install_Instructions_2.png"
                  alt="Screenshot 7"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "ad_instruction_7",
                    }),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,w_187/v1553041742/ISWP-CHINA/Android%20Page/MyISWP_Android_Download_Install_Instructions.png"
                  alt="Screenshot 8"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "ad_instruction_8",
                    }),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041567/ISWP-CHINA/Android%20Page/Rectangle_13.png"
                  alt="Screenshot 9"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "ad_instruction_9",
                    }),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041566/ISWP-CHINA/Android%20Page/Rectangle_14.png"
                  alt="Screenshot 10"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_10" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041566/ISWP-CHINA/Android%20Page/Rectangle_15.png"
                  alt="Screenshot 11"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction>
                  {this.props.intl.formatMessage({ id: "ad_instruction_11" })}
                </Instruction>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column largeScreen="4">
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1553041569/ISWP-CHINA/Android%20Page/Rectangle_16.png"
                  alt="Screenshot 12"
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Instruction
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "ad_instruction_complete",
                    }),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Layout>
    )
  }
}

export default injectIntl(Android)
